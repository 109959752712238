<template>
  <v-container fluid>
    <Header 
      :title="notification.title"
      :subtitle="`Last updated: ${ ('last_updated' in notification) ? new Date(`${notification.last_updated.replaceAll('-', '/')} UTC`).toLocaleString() : '' }`"
      :divider="false"
      :options="options"
    ></Header>

    <ActionDialog ref="delete_dialog" title="Delete Notification" color="error">
      <template v-slot>
        <span class="error--text" v-html="'Warning! Are you sure you want to delete this notification?<br><br>Any associated data such as results files will be lost.'"></span>
      </template>
    </ActionDialog>

    <v-divider class="mt-2 mb-5"/>

    <NotificationDetails :notification="notification">
      <template #actions v-if="notification.type === 'Import' && notification.data !== null">
        <v-divider/>
        <v-card-actions>
          <v-btn class="rounded-lg mx-auto" @click="downloadResultsFile">Download Results<v-icon right>mdi-download</v-icon></v-btn>
        </v-card-actions>
      </template>
    </NotificationDetails>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue';
import ActionDialog from '@/components/ActionDialog.vue';
import NotificationDetails from '@/components/notifications/NotificationDetails.vue';
export default {
  components: {
    Header,
    ActionDialog,
    NotificationDetails,
  },
  computed: {
    notification() {
      return this.$store.getters.getNotification;
    },
    options() {
      let options = [
        {
          'title': 'Delete',
          'title_disabled': `${this.notification.status}`,
          'icon': 'mdi-delete',
          'disabled': this.notification.status !== 'Complete',
          'action': () => this.$refs.delete_dialog.showDialog().then(() => this.$store.dispatch('deleteNotification', this.notification.notification_id)).catch(()=>{})
        },
        {
          'title': `Mark as ${!this.notification.is_read ? 'read' : 'unread'}`,
          'icon': `${this.notification.is_read ? 'mdi-email-open' : 'mdi-email'}`,
          'action': () => this.toggleNotificationRead(this.notification)
        },
      ]
      if (this.notification.cancellable === true && this.notification.status !== 'Complete') {
        options.unshift({
          'title': 'Cancel',
          'icon': 'mdi-cancel',
          'action': () => this.$socket.emit(`cancel_${this.notification.notification_id}`)
        })
      }
      return options;
    }
  },
  created() {
    this.$store.dispatch("getNotification", this.$route.params.id);
  },
  watch: {
    '$route.params.id': {
      handler: async function(newId, oldId) {
        // Only refetch if ID has changed
        if (oldId && newId !== oldId) {
          await this.$store.dispatch("getNotification", newId);
        }
      },
      immediate: true
    },
    notification: {
      handler: async function(newNotification, oldNotification) {
        // If notification has just been marked as complete
        if (newNotification.notification_id === oldNotification.notification_id && newNotification.status !== oldNotification.status && newNotification.status === 'Complete') {
          // Refresh the notification data
          await this.$store.dispatch("getNotification", newNotification.notification_id);
          // Then mark the notification as read and seen
          this.$socket.emit('setNotificationReadStatus', { notification_id: newNotification.notification_id, is_read: true });
          this.$socket.emit('markNotificationsAsSeen', [newNotification.notification_id]);
        }
      },
      deep: true
    }
  },
  methods: {
    toggleNotificationRead(notification) {
      this.$socket.emit('setNotificationReadStatus', { notification_id: notification.notification_id, is_read: !notification.is_read });
    },
    downloadResultsFile() {
      // Remove new lines from description
      let parsed_results = this.notification.data.map(line => {
        if (line.line.html_description) {
          line.line.html_description = `"${line.line.html_description}"`
        }
        return line;
      })
      let csvContent = [
          [
            'Errors',
            ...Object.keys(parsed_results[0].line)
          ],
          ...parsed_results.map(line => [
            line.error,
            ...Object.values(line.line)
          ])
        ]
        .map(e => e.join(","))
        .join("\n");
      const url = window.URL.createObjectURL(
        new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.notification.type} Results.csv`);
      document.body.appendChild(link);
      link.click();
    }
  }
}
</script>