<template>
  <v-dialog v-model="show" :max-width="max_width" @click:outside="show = false" @keydown.esc="show = false">
    <v-card class="rounded-lg">
      <slot name="title" v-bind:options="options">
        <v-card-title>
          {{ title }}
        </v-card-title>
      </slot>
      <v-divider />

      <v-card-text class="pa-4">
        <v-form ref="dialog_form">
          <slot v-bind:options="options" />
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <slot name="actions" v-bind:options="options">
          <v-spacer />
          <v-btn class="rounded-lg" color="grey darken-1" text @click="$refs.dialog_form.resetValidation(); options.cancel()">Cancel</v-btn>
          <v-btn class="rounded-lg" outlined :color="color" @click="$refs.dialog_form.validate() ? options.confirm(options.data) : ()=>{}">{{ confirm_button_text }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    color: {
      type: String,
      default: 'primary'
    },
    max_width: {
      type: String,
      default: '35%'
    },
    confirm_button_text: {
      type: String,
      default: 'Confirm'
    },
  },
  data() {
    return {
      show: false,
      options: {
        data: {},
        confirm: null,
        cancel: null,
      },
    }
  },
  watch: {
    show(showing) {
      if (showing) {
        this.$nextTick(() => {
          this.$refs.dialog_form.resetValidation();
        })
      }
    }
  },
  methods: {
    showDialog(data={}) {
      return new Promise((resolve, reject) => {
        const res = (result) => {
          this.show = false;
          this.options = { data: data, confirm: null, cancel: null };
          resolve(result);
        }
        const rej = () => {
          this.show = false;
          this.options = { data: data, confirm: null, cancel: null };
          reject();
        }
        this.options = { data: data, confirm: res, cancel: rej };
        this.show = true;
      })
    }
  }
}
</script>