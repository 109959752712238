<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <slot name="title">
      <v-card-title class="py-2">
        {{ notification.subtitle }}
        <v-spacer/>
        <slot name="title-actions">
          <v-chip outlined style="white-space: pre;"><b>Status:</b> {{ notification.status }}</v-chip>
        </slot>
      </v-card-title>
      <v-divider/>
    </slot>
    <slot name="text">
      <v-card-text class="pa-4" v-html="notification.text">
      </v-card-text>
    </slot>
    <slot name="actions"></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    notification: Object,
  }
}
</script>